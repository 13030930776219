import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
  routes: [
    {
      path: '/',
      name: 'home',
      component: () => import('@/views/Home.vue')
    },
    {
      path: '/devices',
      name: 'devices',
      component: () => import('@/views/devices/index.vue')
    },
    {
      path: '/devices/add',
      name: 'devices-add',
      component: () => import('@/views/devices/add.vue')
    },
    {
      path: '/devices/edit/:id',
      name: 'devices-edit',
      component: () => import('@/views/devices/edit.vue')
    },
    {
      path: '/places',
      name: 'places',
      component: () => import('@/views/places/index.vue')
    },
    {
      path: '/places/add',
      name: 'places-add',
      component: () => import('@/views/places/add.vue')
    },
    {
      path: '/places/edit/:id',
      name: 'places-edit',
      component: () => import('@/views/places/edit.vue')
    },
    {
      path: '/admins',
      name: 'admins',
      component: () => import('@/views/admins/index.vue')
    },
    {
      path: '/admins/add',
      name: 'admins-add',
      component: () => import('@/views/admins/add.vue')
    },
    {
      path: '/admins/edit/:id',
      name: 'admins-edit',
      component: () => import('@/views/admins/edit.vue')
    },
    {
      path: '/orders',
      name: 'orders',
      component: () => import('@/views/orders/index.vue')
    },
    {
      path: '/logout',
      name: 'logout',
      component: () => import('@/views/auth/logout.vue')
    },
    {
      path: '/login',
      name: 'login',
      component: () => import('@/views/auth/Login.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '/error-404',
      name: 'error-404',
      component: () => import('@/views/error/Error404.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '*',
      name: 'error-4042',
      component: () => import('@/views/error/Error404.vue'),
      meta: {
        layout: 'full',
      },
    },
  ],
})

// ? For splash screen
// Remove afterEach hook if you are not using splash screen
router.afterEach(() => {
  // Remove initial loading
  const appLoading = document.getElementById('loading-bg')
  if (appLoading) {
    appLoading.style.display = 'none'
  }
})

export default router
